function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
(function () {
  let track, valueForTag, validateTag, consumeTag, isConstTag;
  if (true) {
    let validator = Ember.__loader.require('@glimmer/validator');
    track = validator.track;
    valueForTag = validator.valueForTag || validator.value;
    validateTag = validator.validateTag || validator.validate;
    consumeTag = validator.consumeTag || validator.consume;
    isConstTag = validator.isConstTag;
  } else if (true) {
    let metal = Ember.__loader.require('@ember/-internals/metal');
    let reference = Ember.__loader.require('@glimmer/reference');
    track = metal.track;
    consumeTag = metal.consume;
    valueForTag = reference.value;
    validateTag = reference.validate;
    isConstTag = reference.isConstTag;
  } else if (false /* DEBUG */) {
    throw new Error('Attempted to use cache polyfill with unsupported Ember version');
  }
  class Cache {
    constructor(fn) {
      _defineProperty(this, "__lastValue", void 0);
      _defineProperty(this, "__tag", void 0);
      _defineProperty(this, "__snapshot", -1);
      this.__fn = fn;
    }
  }
  Ember._createCache = function createCache(fn) {
    return new Cache(fn);
  };
  Ember._cacheGetValue = function getValue(cache) {
    if (false /* DEBUG */) {
      (false && !(cache instanceof Cache) && Ember.assert(`getValue() can only be used on an instance of a cache created with createCache(). Called with: ${String(cache)}`, cache instanceof Cache));
    }
    let {
      __tag: tag,
      __snapshot: snapshot,
      __fn: fn
    } = cache;
    if (tag === undefined || !validateTag(tag, snapshot)) {
      tag = track(() => cache.__lastValue = fn());
      cache.__tag = tag;
      cache.__snapshot = valueForTag(tag);
      consumeTag(tag);
    } else {
      consumeTag(tag);
    }
    return cache.__lastValue;
  };
  Ember._cacheIsConst = function isConst(cache) {
    if (false /* DEBUG */) {
      (false && !(cache instanceof Cache) && Ember.assert(`isConst() can only be used on an instance of a cache created with createCache(). Called with: ${String(cache)}`, cache instanceof Cache));
      (false && !(cache.__tag) && Ember.assert(`isConst() can only be used on a cache once getValue() has been called at least once. Called with cache function:\n\n${String(cache.__fn)}`, cache.__tag));
    }
    return isConstTag(cache.__tag);
  };
})();