define("ember-cli-chartist/components/chartist-chart", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  <div
    class="ct-chart {{if @ratio @ratio "ct-square"}}"
    ...attributes
    {{did-insert this.initializeChart}}
    {{did-update this.updateChart @data}}
    {{will-destroy this.teardownChart}}
  >
  </div>
  
  */
  {
    id: "vfpC+Z7p",
    block: "{\"symbols\":[\"&default\",\"@ratio\",\"&attrs\",\"@data\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[11,\"div\"],[16,0,[31,[\"ct-chart \",[30,[36,0],[[32,2],[32,2],\"ct-square\"],null]]]],[17,3],[4,[38,1],[[32,0,[\"initializeChart\"]]],null],[4,[38,2],[[32,0,[\"updateChart\"]],[32,4]],null],[4,[38,3],[[32,0,[\"teardownChart\"]]],null],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"did-update\",\"will-destroy\"]}",
    meta: {
      moduleName: "ember-cli-chartist/components/chartist-chart.hbs"
    }
  });
  /* global Chartist */
  let ChartistChart = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ChartistChart extends _component.default {
    // The ratio of the chart as it scales up/down in size.
    //
    // Available ratios:
    //
    // name              ratio
    // ct-square         1
    // ct-minor-second   15:16
    // ct-major-second   8:9
    // ct-minor-third    5:6
    // ct-major-third    4:5
    // ct-perfect-fourth 3:4
    // ct-perfect-fifth  2:3
    // ct-minor-sixth    5:8
    // ct-golden-section 1:1.618
    // ct-major-sixth    3:5
    // ct-minor-seventh  9:16
    // ct-major-seventh  8:15
    // ct-octave         1:2
    // ct-major-tenth    2:5
    // ct-major-eleventh 3:8
    // ct-major-twelfth  1:3
    // ct-double-octave  1:4

    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "chart", _descriptor, this);
      const {
        type
      } = args;

      // Before trying to do anything else, let's check to see if any necessary
      // attributes are missing or if anything else is fishy about attributes
      // provided to the component in the template.
      //
      // We're doing this to help ease people into this project. Instead of just
      // getting some "uncaught exception" we're hoping these error messages will
      // point them in the right direction.
      (false && !(typeof type !== 'undefined' && type !== null) && Ember.assert('Invalid or missing "type" attribute', typeof type !== 'undefined' && type !== null));
    }
    get chartType() {
      return Ember.String.capitalize(this.args.type);
    }
    get updateOnData() {
      return this.args.updateOnData ?? true;
    }
    initializeChart(mountElement) {
      const {
        args: {
          data,
          options,
          responsiveOptions
        },
        chartType
      } = this;
      const chart = new Chartist[chartType](mountElement, data, options, responsiveOptions);
      this.chart = chart;
      this.chart.on('created', this.onCreated);
      this.chart.on('draw', this.onDraw);
      this.chart.on('data', this.onData);
    }
    get onData() {
      return this.args.onData || function () {};
    }
    get onDraw() {
      return this.args.onDraw || function () {};
    }
    get onCreated() {
      return this.args.onCreated || function () {};
    }
    updateChart() {
      const {
        args: {
          data,
          options = {}
        },
        chart,
        updateOnData
      } = this;
      if (typeof data === 'undefined') {
        return;
      }
      if (updateOnData) {
        chart.update(data, options);
      }
    }
    teardownChart() {
      this.chart.detach();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initializeChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initializeChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChart", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownChart", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "teardownChart"), _class.prototype)), _class));
  _exports.default = ChartistChart;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ChartistChart);
});