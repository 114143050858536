define("ember-handle-keys-helper/helpers/handle-keys", ["exports", "ember-handle-keys-helper/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function handleKeys(args, {
    preventDefault,
    stopPropagation
  }) {
    const {
      handler,
      targetKeys
    } = parseArgs(args);
    (false && !(targetKeys.length && targetKeys.every(k => typeof k === 'string')) && Ember.assert(`Expected an optional handler following by target keys, but got ${args}`, targetKeys.length && targetKeys.every(k => typeof k === 'string')));
    return function keyboardEventHandler(event) {
      (false && !(event instanceof KeyboardEvent) && Ember.assert(`Expected an event to be KeyboardEvent, but got ${event}`, event instanceof KeyboardEvent));
      let key = _constants.IE_KEYS_FIX_MAP[event.key] || event.key;
      if (targetKeys.includes(key)) {
        if (preventDefault) {
          event.preventDefault();
        }
        if (stopPropagation) {
          event.stopPropagation();
        }
        if (handler) {
          handler(event);
        }
      }
    };
  });
  _exports.default = _default;
  function parseArgs([handlerOrKey, ...keys]) {
    return typeof handlerOrKey === 'function' ? {
      handler: handlerOrKey,
      targetKeys: keys
    } : {
      handler: null,
      targetKeys: [handlerOrKey, ...keys]
    };
  }
});