define("@coachlogix/cl-ui/components/cl-in-viewport/component", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_emberInViewport.default, {
    didInsertElement() {
      // Spying on scrolling behavior is opt-in
      this.set('viewportSpy', true);
      this._super(...arguments);
    },
    didEnterViewport() {
      (false && !(this.didEnter && typeof this.didEnter === 'function') && Ember.assert('didEnter action must be passed to <ClInViewport> component', this.didEnter && typeof this.didEnter === 'function'));
      this.didEnter();
    }
  });
  _exports.default = _default;
});