define("@ember/-internals/runtime/lib/system/object", ["exports", "@ember/-internals/container", "@ember/-internals/owner", "@ember/-internals/utils", "@ember/-internals/metal", "@ember/-internals/runtime/lib/system/core_object", "@ember/-internals/runtime/lib/mixins/observable", "@ember/debug"], function (_exports, _container, _owner, _utils, _metal, _core_object, _observable, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FrameworkObject = void 0;
  /**
  @module @ember/object
  */

  var instanceOwner = new WeakMap();
  /**
    `EmberObject` is the main base class for all Ember objects. It is a subclass
    of `CoreObject` with the `Observable` mixin applied. For details,
    see the documentation for each of these.
  
    @class EmberObject
    @extends CoreObject
    @uses Observable
    @public
  */

  class EmberObject extends _core_object.default {
    get _debugContainerKey() {
      var factory = _container.FACTORY_FOR.get(this);
      return factory !== undefined && factory.fullName;
    }
    get [_owner.OWNER]() {
      var owner = instanceOwner.get(this);
      if (owner !== undefined) {
        return owner;
      }
      var factory = _container.FACTORY_FOR.get(this);
      return factory !== undefined && factory.owner;
    } // we need a setter here largely to support
    // folks calling `owner.ownerInjection()` API

    set [_owner.OWNER](value) {
      instanceOwner.set(this, value);
    }
  }
  _exports.default = EmberObject;
  (0, _utils.setName)(EmberObject, 'Ember.Object');
  _observable.default.apply(EmberObject.prototype);
  var FrameworkObject;
  _exports.FrameworkObject = FrameworkObject;
  _exports.FrameworkObject = FrameworkObject = class FrameworkObject extends _core_object.default {
    get _debugContainerKey() {
      var factory = _container.FACTORY_FOR.get(this);
      return factory !== undefined && factory.fullName;
    }
    constructor(owner) {
      super();
      (0, _owner.setOwner)(this, owner);
    }
  };
  _observable.default.apply(FrameworkObject.prototype);
  if (false /* DEBUG */) {
    var INIT_WAS_CALLED = (0, _utils.symbol)('INIT_WAS_CALLED');
    var ASSERT_INIT_WAS_CALLED = (0, _utils.symbol)('ASSERT_INIT_WAS_CALLED');
    _exports.FrameworkObject = FrameworkObject = class DebugFrameworkObject extends EmberObject {
      init() {
        super.init(...arguments);
        this[INIT_WAS_CALLED] = true;
      }
      [ASSERT_INIT_WAS_CALLED]() {
        (false && !(this[INIT_WAS_CALLED]) && (0, _debug.assert)(`You must call \`this._super(...arguments);\` when overriding \`init\` on a framework object. Please update ${this} to call \`this._super(...arguments);\` from \`init\`.`, this[INIT_WAS_CALLED]));
      }
    };
    (0, _metal.addListener)(FrameworkObject.prototype, 'init', null, ASSERT_INIT_WAS_CALLED);
  }
});