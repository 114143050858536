define("ember-tui-editor/components/tui-editor", ["exports", "@glimmer/component", "ember-tui-editor/utils/load-locale-file"], function (_exports, _component, _loadLocaleFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert this.setupEditor}} ...attributes>
    {{yield}}
  </div>
  
  {{#if this.editor}}
    {{#each this.tuiOptionsDescriptors as |desc|}}
      {{did-update (fn this.updateOption desc) (get this.args (get desc 0))}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "B1V+xz6Q",
    "block": "{\"symbols\":[\"desc\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[17,2],[4,[38,5],[[32,0,[\"setupEditor\"]]],null],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,6],[[32,0,[\"editor\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"tuiOptionsDescriptors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[30,[36,1],[[32,0,[\"updateOption\"]],[32,1]],null],[30,[36,0],[[32,0,[\"args\"]],[30,[36,0],[[32,1],0],null]],null]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"fn\",\"did-update\",\"-track-array\",\"each\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "ember-tui-editor/components/tui-editor.hbs"
    }
  });
  let TuiEditor = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class TuiEditor extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "editor", _descriptor, this);
      _defineProperty(this, "tuiOptions", ['previewStyle:changePreviewStyle', 'editType:changeMode:initialEditType', 'height:height', 'minHeight:minHeight', 'language', 'useDefaultHTMLSanitizer', 'useCommandShortcut', 'usageStatistics', 'toolbarItems', 'hideModeSwitch', 'viewer', 'value:setMarkdown:initialValue', 'hooks', 'plugins', 'previewHighlight', 'extendedAutolinks', 'customConvertor', 'placeholder:setPlaceholder', 'linkAttribute', 'customHTMLRenderer', 'referenceDefinition', 'customHTMLSanitizer', 'frontMatter']);
    } // here we use a syntax like <property>:<method>:<tui option> to update such property (optional)>
    // splits the options that have a tuiMethod to be used on the template
    // with the {{did-update}} helper
    get tuiOptionsDescriptors() {
      return this.tuiOptions.map(d => d.split(':')).filter(([, tuiMethod]) => !!tuiMethod);
    }

    // gathers all the options to initialize TUI editor, respecting tuiOptions syntax
    get options() {
      let options = {};
      for (let o of this.tuiOptions) {
        let [optionName,, tuiOption] = o.split(':');
        tuiOption = tuiOption ? tuiOption : optionName;
        let value = this.args[optionName];
        if (value !== undefined) {
          options[tuiOption] = value;
        }
      }
      return options;
    }
    async setupEditor(element) {
      await (0, _loadLocaleFile.default)(this.language);
      let module = await emberAutoImportDynamic("@toast-ui/editor");
      this.editor = module.default.factory(Ember.assign(this.options, {
        el: element,
        events: {
          load: (...args) => this.eventInvoked('onLoad', ...args),
          change: (...args) => this.eventInvoked('onChange', this.editor.getMarkdown(), ...args),
          stateChange: (...args) => this.eventInvoked('onStateChange', ...args),
          focus: (...args) => this.eventInvoked('onFocus', ...args),
          blur: (...args) => this.eventInvoked('onBlur', ...args)
        }
      }));
    }

    // tests if an `actionName` function exists and calls it with the arguments if so
    eventInvoked(actionName, ...args) {
      this.args[actionName]?.(...args);
    }
    updateOption([optionName, tuiMethod], [value]) {
      // `value` is a special case because using `setValue`
      // moves the current cursor position so we need to avoid calling it
      // only call it when the editor value is different from the new value we got
      if (optionName === 'value' && this.editor.getMarkdown) {
        let editorValue = this.editor.getMarkdown();
        if (editorValue !== value) {
          this.editor.setMarkdown(value, false);
        }
      } else {
        (false && !(!!this.editor[tuiMethod]) && Ember.assert(`Editor instance should be have a function '${tuiMethod}' but found ${this.editor[tuiMethod]} instead.`, !!this.editor[tuiMethod]));
        this.editor[tuiMethod].call(this.editor, value);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "editor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupEditor", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setupEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOption", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateOption"), _class.prototype)), _class));
  _exports.default = TuiEditor;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TuiEditor);
});