define("ember-in-viewport/modifiers/in-viewport", ["exports", "ember-modifier", "fast-deep-equal"], function (_exports, _emberModifier, _fastDeepEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const WATCHED_ELEMENTS = false /* DEBUG */ ? new WeakSet() : undefined;
  let InViewportModifier = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class InViewportModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "inViewport", _descriptor, this);
      _defineProperty(this, "name", 'in-viewport');
      _defineProperty(this, "lastOptions", void 0);
    }
    get options() {
      // eslint-disable-next-line no-unused-vars
      const {
        onEnter,
        onExit,
        ...options
      } = this.args.named;
      return options;
    }
    get hasStaleOptions() {
      return !(0, _fastDeepEqual.default)(this.options, this.lastOptions);
    }
    validateArguments() {
      (false && !(this.args.positional.length === 0) && Ember.assert(`'{{in-viewport}}' does not accept positional parameters. Specify listeners via 'onEnter' / 'onExit'.`, this.args.positional.length === 0));
      (false && !(typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function') && Ember.assert(`'{{in-viewport}}' either expects 'onEnter', 'onExit' or both to be present.`, typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function'));
    }
    onEnter() {
      if (this.args.named.onEnter) {
        this.args.named.onEnter.call(null, this.element);
      }
      if (!this.options.viewportSpy) {
        this.inViewport.stopWatching(this.element);
      }
    }
    onExit() {
      if (this.args.named.onExit) {
        this.args.named.onExit.call(null, this.element);
      }
    }
    setupWatcher() {
      (false && !(!WATCHED_ELEMENTS.has(this.element)) && Ember.assert(`'${this.element}' is already being watched. Make sure that '{{in-viewport}}' is only used once on this element and that you are not calling 'inViewport.watchElement(element)' in other places.`, !WATCHED_ELEMENTS.has(this.element)));
      if (false /* DEBUG */) WATCHED_ELEMENTS.add(this.element);
      this.inViewport.watchElement(this.element, this.options, this.onEnter, this.onExit);
      this.lastOptions = this.options;
    }
    destroyWatcher() {
      if (false /* DEBUG */) WATCHED_ELEMENTS.delete(this.element);
      this.inViewport.stopWatching(this.element);
    }
    didInstall() {
      this.setupWatcher();
    }
    didUpdateArguments() {
      if (this.hasStaleOptions) {
        this.destroyWatcher();
        this.setupWatcher();
      }
    }
    didReceiveArguments() {
      this.validateArguments();
    }
    willRemove() {
      this.destroyWatcher();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onEnter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onExit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onExit"), _class.prototype)), _class));
  _exports.default = InViewportModifier;
});