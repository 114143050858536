define("ember-power-select/components/power-select-multiple/trigger", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select-multiple/trigger"], function (_exports, _component, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const ua = window && window.navigator ? window.navigator.userAgent : '';
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  let Trigger = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_trigger.default), _dec3 = Ember.inject.service, _dec4 = Ember.computed('select.{searchText.length,selected.length}'), _dec5 = Ember.computed('placeholder', 'select.selected.length'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class Trigger extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "textMeasurer", _descriptor, this);
      _defineProperty(this, "_lastIsOpen", false);
    }
    // CPs
    get triggerMultipleInputStyle() {
      Ember.run.scheduleOnce('actions', this.select.actions.reposition);
      if (!this.select.selected || Ember.get(this.select.selected, 'length') === 0) {
        return Ember.String.htmlSafe('width: 100%;');
      } else {
        let textWidth = 0;
        if (this.inputFont) {
          textWidth = this.textMeasurer.width(this.select.searchText, this.inputFont);
        }
        return Ember.String.htmlSafe(`width: ${textWidth + 25}px`);
      }
    }
    get maybePlaceholder() {
      if (isIE) {
        return undefined;
      }
      return !this.select.selected || Ember.get(this.select.selected, 'length') === 0 ? this.placeholder || '' : '';
    }

    // Lifecycle hooks
    didReceiveAttrs() {
      let oldSelect = this.oldSelect || {};
      this.set('oldSelect', this.select);
      if (oldSelect.isOpen && !this.select.isOpen) {
        Ember.run.scheduleOnce('actions', null, this.select.actions.search, '');
      }
    }

    // Actions
    storeInputStyles(input) {
      let {
        fontStyle,
        fontVariant,
        fontWeight,
        fontSize,
        lineHeight,
        fontFamily
      } = window.getComputedStyle(input);
      this.inputFont = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`;
    }
    chooseOption(e) {
      let selectedIndex = e.target.getAttribute('data-selected-index');
      if (selectedIndex) {
        e.stopPropagation();
        e.preventDefault();
        let object = this.selectedObject(this.select.selected, selectedIndex);
        this.select.actions.choose(object);
      }
    }
    handleInput(e) {
      if (this.onInput && this.onInput(e) === false) {
        return;
      }
      this.select.actions.open(e);
    }
    handleKeydown(e) {
      if (this.onKeydown && this.onKeydown(e) === false) {
        e.stopPropagation();
        return false;
      }
      if (e.keyCode === 8) {
        e.stopPropagation();
        if (Ember.isBlank(e.target.value)) {
          let lastSelection = this.select.selected[this.select.selected.length - 1];
          if (lastSelection) {
            this.select.actions.select(this.buildSelection(lastSelection, this.select), e);
            if (typeof lastSelection === 'string') {
              this.select.actions.search(lastSelection);
            } else {
              (false && !(this.searchField) && Ember.assert('`<PowerSelectMultiple>` requires a `@searchField` when the options are not strings to remove options using backspace', this.searchField));
              this.select.actions.search(Ember.get(lastSelection, this.searchField));
            }
            this.select.actions.open(e);
          }
        }
      } else if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32) {
        // Keys 0-9, a-z or SPACE
        e.stopPropagation();
      }
    }
    selectedObject(list, index) {
      if (list.objectAt) {
        return list.objectAt(index);
      } else {
        return Ember.get(list, index);
      }
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "textMeasurer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "triggerMultipleInputStyle", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "triggerMultipleInputStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "maybePlaceholder", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "maybePlaceholder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "storeInputStyles", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "storeInputStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chooseOption", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "chooseOption"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleInput", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Trigger;
});